<script setup lang="ts">
import { toTypedSchema } from "@vee-validate/zod";
import { z } from "astro/zod";
import { vMaska, type MaskaDetail } from "maska";
import { useForm } from "vee-validate";
import { ref } from "vue";
import { PHONE_MASK } from "~/lib/core/phone";
import CheckIcon from "~icons/sqt/check";
import CloseIcon from "~icons/sqt/close";

const phoneMask = ref<MaskaDetail>({
  completed: false,
  masked: "",
  unmasked: "",
});

const { handleSubmit, defineField, meta, errors } = useForm({
  validationSchema: toTypedSchema(
    z.object({
      phone: z
        .string()
        .min(1, "Required")
        .refine(() => phoneMask.value.completed, {
          message: "Invalid phone number",
        }),
    })
  ),
});

const [phone, phoneAttrs] = defineField("phone");

const onSubmit = handleSubmit(() => {
  window.location.assign(`/auth/subscribe?phone=${phoneMask.value.masked}`);
});
</script>

<template>
  <div class="mx-auto mt-5 max-w-md lg:mt-10 bg-background-alt rounded-2xl p-4">
    <form
      @submit.prevent="onSubmit"
      class="flex flex-col sm:flex-row sm:items-end gap-2"
    >
      <div class="flex-1">
        <div class="flex justify-between font-bold items-center">
          <label for="phone" class="block leading-normal"
            >Your phone number</label
          >
          <span
            v-if="meta.touched && errors.phone"
            class="text-sm bg-theme-red text-utility-black rounded-full px-1.5 py-1"
          >
            {{ errors.phone }}
          </span>
        </div>
        <div class="mt-1 flex tabular-nums font-bold text-lg relative">
          <span
            class="inline-flex items-center rounded-l-full border-2 border-foreground border-r-0 px-3 bg-background"
          >
            +61
          </span>
          <input
            type="text"
            inputmode="numeric"
            name="phone"
            id="phone"
            class="block w-full min-w-0 flex-1 rounded-none rounded-r-full border-0 py-2.5 text-foreground ring-foreground ring-2 ring-inset placeholder:text-foreground/60 focus:ring-[3px] focus:outline-none px-3 tracking-wide disabled:opacity-50"
            v-maska="phoneMask"
            v-model="phone"
            v-bind="phoneAttrs"
            :data-maska="PHONE_MASK"
            :placeholder="PHONE_MASK"
          />
          <div
            v-if="meta.touched"
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <div
              :class="[
                'rounded-full text-utility-black p-1.5',
                errors.phone ? 'bg-theme-red' : 'bg-theme-green',
              ]"
            >
              <CloseIcon
                v-if="errors.phone"
                class="h-4 w-4"
                aria-hidden="true"
              />
              <CheckIcon v-else class="h-4 w-4" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        :disabled="meta.touched && !!errors.phone"
        class="btn btn-primary text-lg disabled:opacity-50 py-2"
      >
        Try for free
      </button>
    </form>
    <p class="mt-2.5 text-xs font-normal lg:text-sm">
      Spam is for losers. You can opt out of our SMS list at any time.<br />
      For more information please see our
      <a class="underline" href="/privacy-policy">privacy policy</a>.
    </p>
  </div>
</template>
